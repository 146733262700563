<template>
  <v-card>
    <v-card-title>{{
      isEditMode ? "Edit Production Schedule" : "New Production Schedule"
    }}</v-card-title>

    <v-card-subtitle>
      {{ product ? product.name : "Product N/A" }}
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-if="customers"
        style="z-index: 100"
        clearable
        label="Customer"
        v-model="production.customerId"
        placeholder="Optional"
        :items="customers"
        item-text="name"
        item-value="id"
      />
      <v-autocomplete
        v-if="productionLines"
        style="z-index: 100"
        clearable
        label="Production Line"
        v-model="production.productionLineId"
        placeholder="Optional"
        :items="productionLines"
        item-text="name"
        item-value="id"
      />
      <v-text-field
        label="Quantity"
        v-model="production.quantity"
        type="number"
      ></v-text-field>
      <date-picker
        :label="$vuetify.lang.t('$vuetify.base.startDate')"
        :defaultNowOnNull="false"
        v-model="production.start"
      />
      <date-picker
        :label="$vuetify.lang.t('$vuetify.base.endDate')"
        :defaultNowOnNull="false"
        v-model="production.end"
      />
      <v-textarea
        label="Memo"
        placeholder="Optional"
        v-model="production.memo"
        :rows="2"
        auto-grow
        outlined
      />
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between">
      <div>
        <v-btn  color="blue darken-1" text @click="save"> {{ isEditMode ? 'Update':'Save'}}</v-btn>
        <v-btn class="ml-2" color="blue darken-1" text @click="cancel">Cancel</v-btn>
      </div>
      <v-btn v-if="isEditMode" color="warning" @click="remove"> Delete </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import {
  deleteProductionScheduleById,
  createProductionSchedule,
  updateProductionSchedule,
} from "@/services/productionScheduleService.js";

export default {
  props: {
    product: {
      type: Object,
      default: "",
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    production: {
      id: null,
      quantity: 0,
      start: "",
      end: "",
      customerId: null,
      productionLineId: null,
      productId: null,
      memo: null
    },
    productionLines: [],
  }),
  computed: {
    ...mapGetters("company", ["customers"]),
    isEditMode() {
      return this.production && this.production.id;
    },
  },
  watch: {
    // Watch the value prop for changes
    value: {
      immediate: true, // This will run the function immediately upon loading
      handler(val) {
        this.production = { ...val };
      },
    },
  },
  created() {
    if(!this.production.productId){
      this.production.productId = this.product.id;
    }
    this.fetchCustomers();
    this.fetchProductionLine();
  },
  methods: {
    ...mapActions("company", ["fetchCustomers"]),

    remove() {
      deleteProductionScheduleById(this.production.id)
        .then(() => {
          const event = {
            productId: this.product.id,
            data: this.production,
            type: "deleted-production",
          };
          this.$emit("on-success", event);
        })
        .catch((error) => {
          console.error(error);
          this.addErrorMessage("Error deleting production schedule: ");
          this.$emit("error", error);
        });
    },
    cancel() {
      this.$emit("on-close");
    },
    save() {
      const cloneProduction = { ...this.production };
        cloneProduction.start = moment(this.production.start).startOf('day').format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        cloneProduction.end = moment(this.production.end).startOf('day').format(
          "YYYY-MM-DDTHH:mm:ss"
        );

      if (cloneProduction.id) {
        updateProductionSchedule(cloneProduction).then((resp) => {
          const event = {
              productId: this.product.id,
              data: this.production,
              type: 'updated-production'
            }
            this.$emit("on-success", event);
        });
      } else {

        createProductionSchedule(cloneProduction)
          .then((resp) => {
            const newId = resp.data;
            this.production.id = newId;

            const event = {
              productId: this.product.id,
              data: this.production,
              type: 'created-new-production'
            }
            this.$emit("on-success", event);
          })
          .catch((error) => {
            this.addErrorMessage(
              "Error creating new production schedule: ",
              error.data.message
            );
            this.$emit("error", error);
          });
      }
    },
    fetchProductionLine() {
      const params = {
        productId: this.product.id,
        size: 1000,
      };

      this.$axios.get("/production-line", { params }).then((resp) => {
        this.productionLines = resp.data.content;
      });
    },
  },
};
</script>
